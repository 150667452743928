import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 5
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"FOUR"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={
          "What is the biggest value of asking a player “How did you do that?”"
        }
        answers={[
          "It helps when you didn’t see a play",
          "It focuses on the process it took to be successful",
          "It calms down the amygdala",
          "It takes the pressure off the coaches to have an answer",
        ]}
        correctAnswerNumber={1}
        answerDetail={`Asking “how did you do that?” allows girls to think about the process they took to be successful. It focuses on the steps that were involved in reaching this point.`}
        nextLink={nextLink}
        nextText={"RESULTS"}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 4, 4)
